html body {
	font-family: $primaryFont;
}
ul {
	list-style: none;
	margin: 0;
}
li {
	cursor: pointer;
}
a {
	text-decoration: none;
}
p {
	font-size: 1rem;
	font-weight: 400;
}
