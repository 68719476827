.awaiting-approval-table {
  margin-top: 1.5rem;
  overflow-x: auto;
  .table {
    thead {
      display: block;
      tr {
        th:last-child {
          min-width: 118px;
        }
      }
    }
    tbody {
      display: block;
      tr {
        & td:last-child {
          min-width: 112px;
          padding-right: 1rem;
          & span:first-child {
            margin-right: 0.75rem;
          }
        }
      }
    }
  }  
}
