.offer-count-per-retailer-table {
  margin-top: 1.5rem;
  .table {
    font-size: 0.875rem;
    tbody {
      color: $textSecondary;
      .retailer-id {
        color: $default;
        font-size: 0.875rem;
        font-weight: 500;
        padding: 1.625rem inherit;
      }
    }
  }
}
