.configurations-tabs {
  .form {
    font-size: 0.875rem;
    font-weight: 500;
    margin-top: 1.875rem;
    height: calc(100vh - 21rem);
    .line-break {
      border-bottom: 1px solid #eaecf0;
      padding: 20px 0px 10px 0px;
    }
    .form-label {
      color: $primary;
    }
    .form-group {
      max-width: 550px;
      margin-top: 1.5rem;
    }
    .form-control {
      border: 1px solid $borderColor;
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      padding: 8px 12px;
      font-size: 1rem;
      font-weight: 400;
      color: $default;
    }
  }
  .btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 1.5rem;
    border-top: 1px solid #eaecf0;
    button:last-child {
      margin-left: 0.75rem;
    }
    .btn {
      padding: 0.625rem 1rem;
    }
    .save {
      padding: 0.625rem;
      width: 200px;
    }
  }
}
