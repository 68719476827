.header {
  h1 {
    font-size: 1.875rem;
    font-weight: 500;
    color: $default
  }
  p {
    color: $textSecondary;
  }
}
