.no-data-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 16rem);
  background-color: #f9f9f9;
  color: #555;

  .no-data-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #333;
  }

  p {
    font-size: 1rem;
    color: #777;
  }
}
