.pagination-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.items-number {
		display: flex;
		align-items: center;
		span {
			margin-right: 0.5rem;
			font-size: 0.875rem;
			font-weight: 600;
			color: $primary;
		}
		.form-select {
			width: 74px;
			padding-right: 1.625rem;
			background-size: 12px;
			color: $default;
			padding: 0.625rem 0.875rem;
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23101828' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
			background-position-y: 20px;
			&:focus {
				outline: none;
				border: 1px solid $borderColor;
				box-shadow: none;
			}
		}
	}
  .pagination {
    margin: 0;
    .page-item {
      &:first-child{
        a.page-link {
          position: relative;
          padding-left: 2.5rem;
          color: $textTertiary;
          &::before {
            content: "";
            position: absolute;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8334 7.50008H1.16669M1.16669 7.50008L7.00002 13.3334M1.16669 7.50008L7.00002 1.66675' stroke='%23344054' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            height: 15px;
            width: 14px;
            top: 50%;
            transform: translateY(-50%);
            left: 0.9375rem;
          }
  
        }
      }
      &:last-child{
        a.page-link {
          position: relative;
          padding-right: 2.5rem;
          color: $textTertiary;
          &::before {
            content: "";
            position: absolute;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8334 7.50008H1.16669M1.16669 7.50008L7.00002 13.3334M1.16669 7.50008L7.00002 1.66675' stroke='%23344054' stroke-width='1.67' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            height: 15px;
            width: 14px;
            top: 52%;
            transform: translateY(-50%) rotate(180deg);
            right: 0.9375rem;
          }
        }
      }
  
    }
    .next {
      svg {
        margin-left: 0.75rem;
        transform: scaleX(-1);
      }
    }
    .page-item {
      &:first-child {
        .page-link {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding-left: 1.25rem;
        }
      }
      &:last-child {
        .page-link {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          padding-right: 1.25rem;
        }
      }
      .page-link {
        border-color: $borderColor;
        outline: none;
        font-size: 0.875rem;
        font-weight: 500;
        padding: 0.5625rem 1rem;
        box-shadow: none;
        color: $primary;
        span {
          color: $primary;
        }
        &:hover {
          background-color: $bgColor;
        }
      }
      &.active {
        .page-link {
          background-color: $bgColor;
          color: #1d2923;
        }
      }
    }
  }
}
