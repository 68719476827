.w-3 {
  width: 3%;
}

.w-6 {
  width: 6%;
}

.w-8 {
  width: 8%;
}

.w-8-5 {
  width: 8.5%;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-11 {
  width: 11%;
}

.w-12 {
  width: 12%;
}

.w-13 {
  width: 13%;
}

.checkbox {
  .form-check-input {
    width: 20px;
    height: 20px;
    box-shadow: none;
    outline: none;
    margin-top: 0;
    border: 1px solid $borderColor;
    border-radius: 6px !important;
    &:checked {
      background-color: $acceptBg;
      border: 1px solid $secondary;
      &[type='checkbox'] {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='6' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6667 1.5L4.25004 7.91667L1.33337 5' stroke='%231C5E9B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.table {
  border: 1px solid $tableBorder;
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  color: $primary;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  svg {
    cursor: pointer;
  }
  td {
    vertical-align: middle;
    color: $default;
    .multiple-goals {
      font-weight: 400;
      color: #101828;
    }
  }
  span {
    display: block;
    color: $textSecondary;
    font-size: 0.875rem;
    font-weight: 400;
  }
  .checkbox {
    margin-right: 0.75rem;
  }
  .grey {
    font-size: 1rem;
    font-weight: 400;
    color: $textSecondary;
  }
  .all-checked {
    display: flex;
    align-items: center;
    color: #2376c2;
    a {
      display: inline-flex;
      align-items: center;
      padding: 0.4375rem 0.75rem;
      text-decoration: none;
      border-radius: 6px;
      height: 36px;
      cursor: pointer;
      &.revoked-all {
        border: 1px solid $errorBg;
        background-color: $errorBg;
        margin-left: 1.75rem;
        margin-right: 0.75rem;
        color: $error;
        span {
          color: $error;
          margin-right: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
        }
      }
      &.accept-all {
        border: 1px solid $acceptBg;
        background-color: $acceptBg;
        color: $secondary;
        span {
          color: $secondary;
          margin-right: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  thead {
    background-color: $bgColor;
    font-size: 14px;
    font-weight: 600;
    color: $textSecondary;
    white-space: pre;
    th {
      padding: 0.75rem 0.375rem;
      font-size: 0.875rem;
      font-weight: 600;
      &:first-child {
        padding-left: 1.5rem;
      }
      &:last-child {
        padding-right: 1rem;
      }
      .sort-id {
        display: flex;
        align-items: center;
        div {
          display: flex;
          svg {
            margin-left: 0.5rem;
            height: 12px;
            width: 12px;
          }
        }
        p {
          margin: 0;
          font-weight: 600;
        }
      }
    }
    td {
      font-size: 0.875rem;
      font-weight: 400;
    }
    .bulk-selected {
      background-color: #fff;
    }
  }
  tbody {
    background-color: #fff;
    font-size: 1rem;
    font-weight: 400;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 52.5vh;
    td {
      padding: 0.375rem;
      height: 72px;
      &:first-child {
        font-weight: 600;
        padding-left: 1.5rem;
      }
      &:last-child {
        padding-right: 1.5rem;
      }
    }
    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      border-radius: 6px;
      &.cancel-btn {
        background-color: $errorBg;
        border: 1px solid $errorBg;
        color: $error;
      }
      &.accept-btn {
        background-color: $acceptBg;
        border: 1px solid $acceptBg;
        color: $secondary;
        border-radius: 8px;
      }
      &.disabled {
        color: $borderColor;
        opacity: 1 !important;
        border: 1px solid #eaecf0;
        background-color: transparent;
      }
    }
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $borderColor;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $textSecondary;
    }
  }
  tfoot {
    background-color: #fff;
    td {
      border-top: 1px solid $tableBorder;
      padding: 0.625rem 1.5rem;
    }
  }
}
.form {
  .errors {
    color: $error;
    margin-top: 3px;
  }
}

.clear-filter {
  color: $error !important;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  --bs-backdrop-opacity: 1;
}

.toastContainer {
  position: static !important;
  & div {
    bottom: 85px !important;
    & > div {
      max-width: unset;
      background-color: transparent;
      box-shadow: unset;
      padding: 0;
      margin: 0;
    }
  }
  .customToast {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.625rem 0.25rem 0.25rem;
    display: flex;
    align-items: center;
    border-radius: 16px;
    &.Error {
      background-color: #ffdbd7;
    }
    &.Success {
      background-color: #d1fadf;
    }
    li {
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 16px;
      &:first-child {
        padding: 0.125rem 0.625rem;
        margin-right: 0.75rem;
      }
    }
    &.Success {
      li {
        color: #027a48;
        &:first-child {
          background-color: #ecfdf3;
        }
      }
    }
    &.Error {
      li {
        color: $error;
        &:first-child {
          background-color: #fef3f2;
        }
      }
    }
  }
}

.disabled {
  user-select: none;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.skeleton {
  .modal-body {
    margin-bottom: 12px !important;
  }
  .btn-wrapper {
    display: flex;
    justify-content: space-between;
    span {
      flex: 0 0 48%;
    }
  }
}

.select-box {
  .selected {
    color: $textSecondary;
    cursor: pointer;
    text-align: left;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }

    .count {
      margin-left: 8px;
      color: $textSecondary;
      font-size: 0.9em;
    }
  }

  .options-container {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid $borderColor;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .search-box {
      padding: 4px 8px;
    }

    .option {
      padding: 8px 12px;
      cursor: pointer;
      color: $textSecondary;

      &:hover {
        color: $primary;
        background-color: #fff;
      }

      &.selected-option {
        background: $acceptBg;
        color: $textPrimary;
      }
    }
  }
}

.tooltip-custom {
  max-width: 400px;
}
