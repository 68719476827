.access {
  display: flex;
  height: 100vh;
  margin-left: 7.1875rem;
  align-items: center;
  .access-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h1 {
      font-size: 3.75rem;
      font-weight: 600;
      margin: 1.5rem auto;
      color: $default;
    }
    span {
      font-size: 1.25rem;
      font-weight: 400;
      color: $textSecondary;
      margin-bottom: 3rem;
    }
    .btn {
      padding: 1rem 1.75rem;
      color: $primary;
      font-size: 1.125rem;
      font-weight: 500;
      border-radius: 8px;
      svg {
        margin-right: 0.9375rem;
      }
    }
  }
}
