.tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  a,
  button {
    color: $textSecondary;
    font-size: 14px;
    font-weight: 500;
    border: none;
    padding: 16px 6px;
    padding-bottom: 17px;
    background: $bgColor;
    outline: none;
    text-decoration: none;
    &:not(:last-child) {
      margin-right: 24px;
    }
    &.active {
      border-bottom: 2px solid $textPrimary;
      color: $textPrimary;
      background-color: $bgColor;
    }
    &:hover {
      color: $textPrimary;
    }
    &:focus {
      border-bottom: 2px solid $textPrimary;
    }
  }
}
.offers-tabs {
  .upload-offers-wrapper {
    position: relative;
    height: 74vh;
    .upload-offers-card {
      padding: 24px;
      border: 1px solid $tableBorder;
      border-radius: 12px;
      width: 580px;
      max-width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      span {
        font-size: 14px;
        font-weight: 400;
        color: $textSecondary;
      }
      .card-body {
        background-color: #fff;
        padding: 0;
        .card-title {
          font-size: 20px;
          font-weight: 600;
          color: $default;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .btn {
            padding: 10px 16px;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .card-subtitle {
          color: $textSecondary;
          font-size: 16px;
          font-weight: 400;
          margin-top: 6px;
          margin-bottom: 20px !important;
        }
        label {
          width: 100%;
        }
        .loader-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            color: $secondary !important;
          }
        }
        .file-uploader {
          text-align: center;
          display: flex;
          justify-content: center;
          border: 1px solid #eaecf0;
          border-radius: 8px;
          flex-direction: column;
          padding: 16px;
          .upload-text {
            margin-top: 12px;
            color: $textSecondary;
            font-weight: 400;
            h3 {
              font-size: 16px;
              font-weight: 400;
              span {
                cursor: pointer;
                color: $textPrimary;
                font-weight: 600;
                font-size: 16px;
              }
            }
            p {
              font-weight: 400;
              font-size: 12px;
            }
          }
          .upload-icon {
            cursor: pointer;
          }
        }
      }
      .file-upload-details {
        border: 1px solid #eaecf0;
        border-radius: 8px;
        display: flex;
        padding: 16px 18px;
        background-color: $bgColor;
        margin-top: 16px;
        .delete-icon {
          cursor: pointer;
        }
        .attached-file-icon {
          flex: 0 0 32px;
          min-width: 32px;
          margin-right: 14px;
        }
        .attached-file-overview {
          flex: 1;
          display: flex;
          flex-direction: column;
          .attached-file-name {
            display: flex;
            justify-content: space-between;
            h4 {
              font-size: 14px;
              font-weight: 500;
              color: $primary;
              word-break: break-all;
            }
          }
          .attached-file-stats {
            display: flex;
            justify-content: space-between;
          }
        }
        &.invalid-file {
          border: 1px solid #fecdca;
          background-color: #fef3f2;
          .attached-file-overview {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            h4 {
              margin-bottom: 0;
              color: #7a271a;
            }
            .attached-file-stats {
              span {
                color: #b42318;
              }
            }
            .attached-file-name {
              flex-direction: column;
            }
            .delete-icon {
              color: #7a271a;
            }
          }
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 24px;
        column-gap: 12px;
        span {
          color: #fff;
          font-size: 16px;
          font-weight: 500;
        }
        .btn-light {
          flex: 0 0 48%;
        }
        .btn-primary {
          flex: 1;
        }
      }
    }
  }
}

.upload-file-validation-table {
  margin-bottom: 24px;
  margin-top: 24px;
  overflow-x: auto;
  table {
    thead {
      display: block;
      tr {
        display: flex;
        td {
          font-weight: 600;
        }
      }
    }
    tbody {
      display: block;
      max-height: 75vh;
    }
    tr {
      display: flex;
      td:first-child {
        flex: 0 0 250px;
        max-width: 250px;
        display: flex;
        align-items: center;
        height: 72px;
        padding: 12px;
        padding-left: 24px;
      }
      td:last-child {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 12px;
        padding-left: 24px;
      }
    }
  }
}
