.offers-modal {
	.modal-dialog {
		margin: 0 auto !important;
		height: 100vh;
		width: 400px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.modal-content {
			border: 0;
			box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
			border-radius: 12px;
			padding: 1.5rem;
			.modal-header {
				display: flex;
				flex-direction: column;
				justify-content: center;
				border: none;
				padding: 0;
			}
			.modal-body {
				display: flex;
				flex-direction: column;
				color: $textSecondary;
				text-align: center;
				font-size: 0.875rem;
				font-weight: 400;
				padding: 0;
				margin-bottom: 2rem;
				h5 {
					font-size: 1.125rem;
					font-weight: 500;
					margin-top: 1.25rem;
					margin-bottom: 0.5rem;
					color: $default;
				}
				.reject-all-icon {
					display: block;
					position: relative;
					height: 55px;
					width: 55px;
					margin: auto;
					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						z-index: 3;
					}
					&::before {
						content: "";
						position: absolute;
						width: 35px;
						height: 35px;
						background: $error	;
						top: 50%;
						left: 50%;
						border-radius: 50%;
						transform: translate(-50%, -50%);
						z-index: 2;
						opacity: .3;
					}
					&::after {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						background: $error	;
						top: 50%;
						left: 50%;
						border-radius: 50%;
						transform: translate(-50%, -50%);
						opacity: .2;
					}
				}
			}
			.modal-footer {
				border: none;
				padding: 0;
				.btn {
					font-size: 1rem;
					font-weight: 500;
				}
				.btn-light {
					flex: 0 0 48%;
					border: 1px solid #d0d5dd;
					box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
					border-radius: 8px;
					&:hover {
						opacity: 0.7;
					}
				}
				.btn-primary {
					flex: 1;
					background: $secondary;
					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}
}
