@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
$primaryFont: 'Inter';
$secondaryFont: 'Cairo';
$tertiaryFont: 'Noto Sans Arabic';
$quadraticFont: 'Noto Sans';
$primary: #344054;
$secondary: #1C5E9B;
$textPrimary:#195388;
$textSecondary: #667085;
$textTertiary: #1D2939;
$borderColor: #D0D5DD;
$default: #101828;
$bgColor: #f9fafb;
$error: #D92D20;
$errorBg: #FEF3F2;
$acceptBg: #ECF3FA;
$tableBorder: #E4E7EC;
