.authenticated-wrapper {
	display: flex;
	.sidebar-menu-wrapper {
		flex: 0 0 312px;
		max-width: 312px;
	}
	.authenticated-content-wrapper {
		position: relative;
		flex: 1;
		background-color: $bgColor;
		padding: 2rem 2.1875rem 2.875rem 2rem;
		width: calc(100% - 312px);
		height: 100vh;
		overflow: hidden;
	}
}
