.sidebar {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  width: 312px;
  .logo {
    cursor: pointer;
    display: inline-block;
    padding-left: 0.875rem;
  }
  .sidebar-menu {
    margin-top: 1.5rem;
    svg {
      margin-right: 1rem;
    }
    .title {
      color: $primary;
      text-transform: uppercase;
    }
    ul {
      padding: 0;
      li {
        color: $primary;
        outline: none;
        &:hover {
          a {
            color: $textPrimary;
          }
        }
        a {
          text-decoration: none;
          color: $primary;
          display: block;
          padding: 0.625rem 0.875rem;
          font-weight: 500;
        }
        .active {
          background: $acceptBg;
          color: $primary;
          border-radius: 6px;
          color: $textPrimary;
        }
      }
    }
  }
  .sidebar-footer {
    border-top: 1px solid #EAECF0;
    padding-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-size: 0.875rem;
      font-weight: 500;
      color: $default;
      margin: 0;
    }
    span {
      font-size: 0.875rem;
      font-weight: 400;
      color: $textSecondary;
    }
    .logout-icon {
      cursor: pointer;
    }
  }
}
