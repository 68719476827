.filters {
  margin-top: 1.75rem;
  display: flex;
  .form-group {
    position: relative;
    &:not(:first-child) {
      margin-left: 1rem;
    }
    .form-control {
      border: 1px solid $borderColor;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      padding: 0.625rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      color: $textSecondary;
      &::placeholder {
        color: $textSecondary;
      }
      &.type-filter {
        padding-right: 1.75rem;
      }
    }
    &.search {
      flex: 1;
      margin-right: 1rem;
      &::before {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 16L12.875 12.375M14.8333 7.66667C14.8333 11.3486 11.8486 14.3333 8.16667 14.3333C4.48477 14.3333 1.5 11.3486 1.5 7.66667C1.5 3.98477 4.48477 1 8.16667 1C11.8486 1 14.8333 3.98477 14.8333 7.66667Z' stroke='%23101828' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        top: 50%;
        left: 14px;
        transform: translateY(-50%);
      }
      .form-control {
        padding-left: 2.625rem;
      }
    }
    &.date-picker {
      width: 150px;
      .react-datepicker__input-container {
        input {
          cursor: pointer;
          border: 1px solid $borderColor;
          background: #ffffff;
          border-radius: 8px;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          font-size: 16px;
          font-weight: 400;
          padding: 10px 12px;
          color: $textSecondary;
          outline: none;
          width: 100%;
        }
      }
    }
    &.type-filter {
      width: 150px;
      select {
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: 95% center;
        background-image: url('data:image/svg+xml,%0A%3Csvg width=%2712%27 height=%278%27 viewBox=%270 0 12 8%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M1 1.5L6 6.5L11 1.5%27 stroke=%27%2398A2B3%27 stroke-width=%271.66667%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A');
      }
    }
  }
  .date-fields-wrapper {
    flex: 0 0 615px;
    display: flex;
    align-items: center;
  }
}
