.all-offers-table {
  margin-top: 1.5rem;
  overflow-x: auto;
  table {
    thead {
      display: block;
      th {
        &:last-child {
          text-align: center;
          min-width: 99px;
        }
      }
    }
    tbody {
      display: block;
      td {
        .status-chip {
          display: inline-block;
          font-size: 0.75rem;
          font-weight: 500;
          border-radius: 16px;
          padding: 2px 8px;
          text-align: center;
          text-transform: capitalize;
        }
        .started {
          background-color: #fffaeb;
          color: #b54708;
        }
        .expired {
          background-color: #f2f4f7;
          color: $primary;
        }
        .created {
          background-color: #eff8ff;
          color: #175cd3;
        }
        .revoked {
          background-color: $errorBg;
          color: #b42318;
        }
        .achieved {
          background-color: #ecfdf3;
          color: #027a48;
        }
        .approved {
          background-color: #ecfdf3;
          color: #027a48;
        }
        .redeemed {
          background-color: #f2f4f7;
          color: $primary;
        }
        .redeemed_expired {
          background-color: #f2f4f7;
          color: $primary;
        }
        .reject-button {
          text-align: center;
          width: 36px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: $errorBg;
          border: 1px solid $errorBg;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
        .disabled {
          opacity: 0.3;
          pointer-events: none;
        }
        &:last-child {
          padding-right: 6px;
          min-width: 94px;
        }
      }
    }
  }
}
