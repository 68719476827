.timezone-and-currency {
  font-size: 1rem;
  font-weight: 400;
  color: $default;
  .form-select {
    background-image: url('data:image/svg+xml,%0A%3Csvg width=%2712%27 height=%278%27 viewBox=%270 0 12 8%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M1 1.5L6 6.5L11 1.5%27 stroke=%27%2398A2B3%27 stroke-width=%271.66667%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A');
    background-size: 15px 10px;
    padding: 0.625rem 1.75rem 0.625rem 2.625rem;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $borderColor;
      border-radius: 8px;
    }
  }
  .country {
    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
      padding: 0;
    }
    .ReactFlagsSelect-module_selectBtn__19wW7 {
      outline: none;
      box-shadow: none;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid $borderColor;
      box-shadow: none;
    }
    .ReactFlagsSelect-module_selectBtn__19wW7:after {
      border: none;
      width: 15px;
      height: 10px;
      background-image: url('data:image/svg+xml,%0A%3Csvg width=%2712%27 height=%278%27 viewBox=%270 0 12 8%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M1 1.5L6 6.5L11 1.5%27 stroke=%27%2398A2B3%27 stroke-width=%271.66667%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A');
      background-repeat: no-repeat;
      background-size: 100%;
      margin: 0;
    }
  }
  .currency {
    position: relative;
    select {
      cursor: pointer;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 15px;
      width: 19px;
      height: 14px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='14' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 5.33317V8.6665M15 5.33317V8.6665M1.66666 3.83317L1.66666 10.1665C1.66666 11.0999 1.66666 11.5666 1.84832 11.9232C2.00811 12.2368 2.26308 12.4917 2.57668 12.6515C2.9332 12.8332 3.39991 12.8332 4.33333 12.8332L15.6667 12.8332C16.6001 12.8332 17.0668 12.8332 17.4233 12.6515C17.7369 12.4917 17.9919 12.2368 18.1517 11.9232C18.3333 11.5666 18.3333 11.0999 18.3333 10.1665V3.83317C18.3333 2.89975 18.3333 2.43304 18.1517 2.07652C17.9919 1.76292 17.7369 1.50795 17.4233 1.34816C17.0668 1.16651 16.6001 1.16651 15.6667 1.1665L4.33333 1.1665C3.39991 1.1665 2.9332 1.1665 2.57668 1.34816C2.26308 1.50795 2.00811 1.76292 1.84832 2.07652C1.66666 2.43304 1.66666 2.89975 1.66666 3.83317ZM12.0833 6.99984C12.0833 8.15043 11.1506 9.08317 10 9.08317C8.8494 9.08317 7.91666 8.15043 7.91666 6.99984C7.91666 5.84924 8.8494 4.9165 10 4.9165C11.1506 4.9165 12.0833 5.84924 12.0833 6.99984Z' stroke='%23667085' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      transform: translateY(-50%);
    } 
  }
  .budget-period {
    display: flex;
    flex-direction: column;
    .form-control:focus {
      outline: none;
      border: none;
    }
  }
  .timezone-field {
    .timezone {
      position: relative;
      select.form-control {
        padding: 0.625rem 1.75rem 0.625rem 2.625rem;
        cursor: pointer;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border: 1px solid $borderColor;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-position: 98% center;
        background-size: 15px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='%2398A2B3' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
      &::after {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_7433_1549)'%3E%3Cpath d='M10 4.99984V9.99984L13.3333 11.6665M18.3333 9.99984C18.3333 14.6022 14.6024 18.3332 10 18.3332C5.39762 18.3332 1.66666 14.6022 1.66666 9.99984C1.66666 5.39746 5.39762 1.6665 10 1.6665C14.6024 1.6665 18.3333 5.39746 18.3333 9.99984Z' stroke='%23667085' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_7433_1549'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  
  .region {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
      color: $textPrimary;
    }
    .btn-wrapper {
      display: flex;
     justify-content: space-between;
     flex-direction: row;
    }
  }
}
