.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  transition: all 0.5s ease;
  padding: 0.75rem 1.125rem;
  font-size: 1rem;
  font-weight: 500;
  vertical-align: middle;
  position: relative;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid $borderColor;
  border-radius: 8px;
  outline: none;
  min-width: 100px;
  &:hover{
    opacity: 0.7;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &.btn-light {
    @extend .btn;
    background-color: #fff;
    color: $primary;
    border: 1px solid $borderColor;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: $borderColor !important;
  }
  &.btn-primary {
    @extend .btn;
    background: $secondary !important;
    color: #fff !important;
    border: 1px solid $secondary;
  }
}
